<template>
  <v-app-bar
    absolute
    app
    color="transparent"
    flat
    height="60"
    style="width: auto;"
  >
    <v-container class="px-0 d-flex">
      <v-toolbar-title
        class="appbar-header"
        v-text="toolbarTitle"
      />
      <v-spacer />
      <service-number-search
        class="pt-3"
      />
    </v-container>
  </v-app-bar>
</template>

<script>
    // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters, mapActions } = createNamespacedHelpers('app')
  export default {
    name: 'DashboardCoreAppBar',

    components: {
      ServiceNumberSearch: () => import('./Search'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'friends with Andrew',
        'Another Notification',
        'Another one',
      ],
      profile: [
        { title: 'Profile' },
        { title: 'Settings' },
        { divider: true },
        { title: 'Log out' },
      ],
    }),

    computed: {
      ...mapGetters(['appDrawer', 'customerInfo', 'globalFilter']),
      toolbarTitle: function () {
        // console.log('AppBar route name is ' + this.$route.name + ' ' + this.customerInfo.customerName)
        if (this.globalFilter && this.globalFilter !== 'ALL') {
          if (this.$route.name === 'Dashboard') {
            return this.customerInfo.customerName + ' for Account ' + this.globalFilter
          } else {
            return this.customerInfo.customerName + ' for Account ' + this.globalFilter + ' - ' + this.$route.name
          }
        } else {
          if (this.$route.name === 'Dashboard') {
            return this.customerInfo.customerName
          } else {
            return this.customerInfo.customerName + ' - ' + this.$route.name
          }
          // return this.$route.name + ' ' + this.customerInfo.customerName
        }
      },
    },

    methods: {
      ...mapActions(['setAppDrawer']),
    },
  }
</script>
